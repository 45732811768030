<template>
  <div class="discovery-search flex flex-col gap-1 px-2 pt-2 pb-1 rounded-xl" :class="{'expanded': dropdownExpanded}"
  ref="search-container" v-on-clickaway="() => {collapseDropdown()}">
    <!-- SEARCH INPUT -->
    <div class="search-input-container w-full flex items-center gap-1.5 pl-1.5 py-1.5 pr-1 rounded-md"
    :class="dropdownExpanded ? 'expanded' : 'collapsed'" @click="expandDropdown"
    @mouseenter="searchBarHovered = true" @mouseleave="searchBarHovered = false">
      <DynamicSearchIcon class="flex-shrink-0" :isHovered="searchBarHovered" :isActive="dropdownExpanded" />
      <input 
        ref="search-input"
        :value="textSearch"
        class="search-input flex-grow max-w-none min-w-0" 
        autocomplete="off" type="text" spellcheck="false"
        :placeholder="searchPlaceholderText"
        @click.stop
        @input="handleSearchInput"
        @focus="() => { searchInputFocused = true; if (!dropdownExpanded) expandDropdown() }" 
        @blur="searchInputFocused = false"
        @keydown.enter.prevent @keydown.up.prevent @keydown.down.prevent @keydown.escape.prevent @keydown.tab.prevent
      />
      <!-- Clear search button / search hotkey indicator -->
      <transition name="input-action-swap">
        <button v-if="textSearch.length" class="remove-button p-0.5 text-neutral-alpha-600" style="border-radius: 4px"
        @click.stop="handleClearSearch">
          <BoardRemoveIcon />
        </button>
        <div v-else-if="!dropdownExpanded" class="flex items-center px-2 py-0.5 gap-px transition-colors duration-100"
        :class="searchBarHovered ? 'text-text-muted' : 'text-text-subdued'">
          <BaseText type="label" size="xs">⌘</BaseText>
          <BaseText type="body" size="xs">K</BaseText>
        </div>
      </transition>
    </div>
    <!-- DROPDOWN CONTENT -->
    <transition name="dropdown">
      <div v-if="dropdownExpanded" class="w-full flex flex-col gap-1">
        <!-- RECENT SEARCHES / KEYWORD SEARCH INFO -->
        <transition name="rs-kws-swap">
          <div v-if="!textSearch.length && recentSearches.length" class="relative w-full flex items-center gap-1 flex-nowrap" key="rs">
            <BaseText type="body" size="sm" class="text-neutral-alpha-650 px-2 py-2.5 whitespace-nowrap">
              Recent Searches
            </BaseText>
            <!-- left-side fade overlay -->
            <div v-if="!rsMinScroll" class="rs-left-fade-overlay absolute top-0 bottom-0 w-16 origin-left" style="left: 131px" />
            <!-- scroll left nav button -->
            <transition name="fade">
              <button v-if="!rsMinScroll" @click="() => scrollRecentSearches('left')"
              class="scroll-nav-btn absolute top-1/2 transform -translate-y-1/2 w-6 h-6 p-0.5" style="left: 132px">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="relative" style="right: 0.5px">
                  <path d="M11.6667 13.3334L8.92267 10.5893C8.59723 10.2638 8.59723 9.7362 8.92267 9.41076L11.6667 6.66669" 
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </transition>
            <!-- Scrollable list -->
            <div ref="recent-searches" class="flex-grow flex items-center gap-1.5 overflow-x-scroll scrollbar-hide">
              <button v-for="(search, index) in recentSearches" :key="`recent-search-${index}`"
              class="recent-search group flex items-center p-0.5 rounded-md"
              @click="handleSearchSubmit(search)">
                <BaseText type="label" size="xs" 
                class="px-1.5 text-neutral-alpha-800 transition-colors duration-100 group-hover:text-white whitespace-nowrap">
                  {{ search }}
                </BaseText>
                <button class="remove-button p-0.5 text-neutral-alpha-600" style="border-radius: 4px"
                @click.stop="updateRecentSearches(search, true)">
                  <BoardRemoveIcon />
                </button>
              </button>
            </div>
            <!-- right-side fade overlay -->
            <div v-if="rsOverflows && !rsMaxScroll" class="rs-right-fade-overlay absolute top-0 bottom-0 -right-px w-16 origin-right" ref="right-fade"/>
            <!-- scroll right nav button -->
            <transition name="fade">
              <button v-if="rsOverflows && !rsMaxScroll" @click="() => scrollRecentSearches('right')"
              class="scroll-nav-btn absolute top-1/2 transform -translate-y-1/2 right-0 w-6 h-6 p-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="relative left-px">
                  <path d="M8.33325 13.3334L11.0773 10.5893C11.4028 10.2638 11.4028 9.7362 11.0773 9.41076L8.33325 6.66669" 
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </transition>
          </div>
          <!-- kws info text -->
          <div v-else-if="textSearch.length < MIN_KEYWORD_LENGTH" class="w-full" key="kws">
            <BaseText type="body" size="sm" class="text-neutral-alpha-400 px-2 py-2.5">
              Type at least {{ MIN_KEYWORD_LENGTH }} letters to make a keyword search.
            </BaseText>
          </div>
          <!-- KEYWORD SEARCH OPTION -->
          <button v-else class="flex items-center py-1.5 pl-1.5 pr-2 rounded-md min-w-0 transition-colors duration-100"
          :class="currentNavItem?.type === 'keywordSearch' ? 'bg-neutral-malpha-50' : 'hover:bg-neutral-malpha-50'"
          @click="() => { handleSearchSubmit() }" @mouseenter="navIndex = null">
            <div class="p-1 rounded-md bg-neutral-malpha-50 flex-shrink-0 mr-3">
              <KeywordSearchIcon class="text-white" />
            </div>
            <BaseText type="body" size="sm" class="text-neutral-alpha-800">"</BaseText>
            <BaseText type="body" size="sm" class="text-neutral-alpha-800 flex-shrink truncate">
              {{ textSearch }}
            </BaseText>
            <BaseText type="body" size="sm" class="text-neutral-alpha-800">"</BaseText>
            <BaseText type="body" size="sm" class="text-neutral-alpha-600 ml-3 flex-shrink-0 whitespace-nowrap">
              Keyword Search
            </BaseText>
          </button>
        </transition>
        <!-- divider -->
        <div v-if="autocompleteOptions || loadingAutocomplete" class="w-full h-px bg-neutral-alpha-200" />
        <!-- loading state -->
        <div v-if="loadingAutocomplete" class="w-full flex justify-center py-4">
          <BaseLoadingLogo noPadding :margin="0" :width="32" :height="32" />
        </div>
        <!-- AUTOCOMPLETE OPTIONS -->
        <div v-if="autocompleteOptions && !loadingAutocomplete" class="relative w-full">
          <!-- top fade overlay -->
          <div v-if="!acMinScroll" class="ac-top-fade-overlay absolute top-0 left-0 right-0 h-5" />
          <!-- rendered list of options -->
          <div ref="ac-options-list" class="w-full flex flex-col gap-1 overflow-y-scroll scrollbar-hide" 
          style="max-height: 268px">
            <AutocompleteOption v-for="(option, index) in autocompleteOptions" :key="`ac-option-${index}`"
              :ref="`ac-option-${index}`"
              :option="option"
              :isNavActive="currentNavItem?.ref === `ac-option-${index}`"
              @mouseenter.native="navIndex = null"
            />
          </div>
          <!-- bottom fade overlay -->
          <div v-if="acOverflows && !acMaxScroll" class="ac-bottom-fade-overlay absolute bottom-0 left-0 right-0 h-5" />
        </div>
        <!-- divider -->
        <div v-if="autocompleteOptions && !loadingAutocomplete" class="w-full h-px bg-neutral-alpha-200" />
        <!-- Keyboard navigation controls legend -->
        <div v-if="autocompleteOptions && !loadingAutocomplete" class="w-full flex items-center gap-4 px-2 py-1.5">
          <div class="flex items-center gap-1.5">
            <div class="p-0.5 border border-neutral-alpha-200" style="border-radius: 4px">
              <ReturnKeyIcon class="text-neutral-alpha-800" />
            </div>
            <BaseText type="body" size="xs" class="text-white">
              to select
            </BaseText>
          </div>
          <div class="flex items-center gap-1.5">
            <div class="relative h-6 p-0.5 border border-neutral-alpha-200" style="border-radius: 4px; width: 38px">
              <UpArrowKeyIcon class="absolute left-px top-px bottom-0.5 text-neutral-alpha-800" />
              <DownArrowKeyIcon class="absolute right-px bottom-0.5 text-neutral-alpha-800" />
            </div>
            <BaseText type="body" size="xs" class="text-white">
              to navigate
            </BaseText>
          </div>
          <div class="ml-auto flex items-center gap-1.5">
            <BaseText type="label" size="xs" class="px-1.5 py-1 border border-neutral-alpha-200 text-neutral-alpha-600" style="border-radius: 4px">
              ESC
            </BaseText>
            <BaseText type="body" size="xs" class="text-white">
              to close
            </BaseText>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import { sendEventSocket } from '@/api/sockets'
import smoothReflow from 'vue-smooth-reflow'
import AutocompleteOption from './AutocompleteOption.vue'

// Icons
import DynamicSearchIcon from '../globals/Icons/DiscoveryIcons/DynamicSearchIcon.vue'
import BoardRemoveIcon from '../globals/Icons/BoardRemoveIcon.vue'
import KeywordSearchIcon from '../globals/Icons/DiscoveryIcons/SearchIcons/KeywordSearchIcon.vue'
import ReturnKeyIcon from '../globals/Icons/DiscoveryIcons/SearchIcons/ReturnKeyIcon.vue'
import UpArrowKeyIcon from '../globals/Icons/DiscoveryIcons/SearchIcons/UpArrowKeyIcon.vue'
import DownArrowKeyIcon from '../globals/Icons/DiscoveryIcons/SearchIcons/DownArrowKeyIcon.vue'

// Constants
const MIN_KEYWORD_LENGTH = 3

export default {
  name: 'DiscoverySearch',
  mixins: [clickaway, smoothReflow],
  components: {
    AutocompleteOption,
    DynamicSearchIcon,
    BoardRemoveIcon,
    KeywordSearchIcon,
    ReturnKeyIcon,
    UpArrowKeyIcon,
    DownArrowKeyIcon
  },
  props: {
    textSearch: {
      type: String,
      default: () => ''
    },
    keywordsOnly: {
      type: Boolean,
      default: false
    },
    brandsOnly: {
      type: Boolean,
      default: false
    },
    currentBrand: {
      type: Object,
      default: () => null
    },
    qualifier: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      // Data States
      autocompleteResults: null,
      navIndex: null,

      // UI States
      dropdownExpanded: false,
      searchBarHovered: false,
      searchInputFocused: false,
      rsOverflows: false,
      rsMinScroll: true,
      rsMaxScroll: false,
      acOverflows: false,
      acMinScroll: true,
      acMaxScroll: false,
      loadingAutocomplete: false,

      // Constants
      MIN_KEYWORD_LENGTH: MIN_KEYWORD_LENGTH
    }
  },
  mounted () {
    if (this.textSearch.length) this.fetchAutocomplete(this.textSearch)
    this.$nextTick(() => {
      this.$smoothReflow({
        el: this.$refs['search-container'],
        property: 'height',
        transition: 'height 100ms ease-in-out'
      })
    })
  },
  beforeDestroy () {
    this.$unsmoothReflow({ el: this.$refs['search-container'] })
    window.removeEventListener('keydown', this.handleKeyboardNavigation)
  },
  watch: {
    textSearch () {
      this.navIndex = null
    },
    recentSearches () {
      this.$nextTick(() => {
        this.handleRecentSearchScroll()
      })
    },
    recentSearchesVisible (visible) {
      if (visible) {
        this.$nextTick(() => {
          this.handleRecentSearchScroll()
          this.$refs['recent-searches'].addEventListener('scroll', this.handleRecentSearchScroll)
        })
      }
    },
    autoCompleteOptionsVisible (visible) {
      if (visible) {
        this.$nextTick(() => {
          this.handleAutocompleteScroll()
          this.$refs['ac-options-list'].addEventListener('scroll', this.handleAutocompleteScroll)
        })
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    recentSearches () {
      return this.getUser?.recentSearches?.map(s => s.search) || []
    },
    recentSearchesVisible () {
      return this.dropdownExpanded && !this.textSearch.length && this.recentSearches.length
    },
    autoCompleteOptionsVisible () {
      return this.dropdownExpanded && this.autocompleteOptions && !this.loadingAutocomplete
    },
    searchPlaceholderText () {
      const baseAction = this.keywordsOnly 
        ? 'Search keywords' 
        : this.brandsOnly ? 'Search keywords, brands' : 'Search keywords, brands, categories'
      return this.qualifier.length ? `${baseAction} in ${this.qualifier}...` : `${baseAction}...`
    },
    autocompleteOptions () {
      if (!this.autocompleteResults || !this.textSearch.length) return null

      // Start out with the brand suggestions at the top
      let options = [...this.autocompleteResults.brands.slice(0, this.brandsOnly ? 5 : 4).map(brand => ({
        type: 'brand',
        title: 'Brand',
        name: brand.name,
        count: brand.count,
        image: brand.imageUrl,
        action: () => { 
          this.$router.push({ name: 'DiscoveryBrandView', params: { id: brand.id }}) 
        }
      }))]

      // Return early if we are only showing brands in the autocomplete
      if (this.brandsOnly) return options

      // Add the product categories and creative targeting, and sort them together by count
      const categories = this.autocompleteResults.productCategory
        .filter(category => category.name !== 'unknown')
        .slice(0, 4)
        .map(category => ({
          type: 'productCategory',
          title: 'Product Category',
          name: category.name,
          count: category.count,
          action: () => { 
            this.$router.push({ name: 'DiscoveryProductCategoryView', params: { category: category.name.replace(/ /g, '-').toLowerCase() }})
          }
        }))
      const creativeTargets = this.autocompleteResults.creativeTargeting
        .filter(target => target.name !== 'unknown')
        .slice(0, 4)
        .map(target => ({
          type: 'creativeTarget',
          title: 'Creative Target',
          name: target.name,
          count: target.count,
          action: () => { 
            this.$router.push({ name: 'DiscoveryCreativeTargetingView', params: { category: target.name.replace(/ /g, '-').toLowerCase() }})
          }
        }))
      options.push(...[...categories, ...creativeTargets].sort((a, b) => b.count - a.count))
      return options
    },
    navigationItems () {
      // We include the keyword search option at the top of the list if it's visible
      const inputOptions = this.textSearch.length >= MIN_KEYWORD_LENGTH ? [{
        type: 'keywordSearch',
        action: () => { this.handleSearchSubmit() }
      }] : []
      const acOptions = this.autocompleteOptions?.map((option, index) => ({
        ...option,
        ref: `ac-option-${index}`,
      })) ?? []
      return [...inputOptions, ...acOptions]
    },
    currentNavItem () {
      return this.navIndex !== null ? this.navigationItems[this.navIndex] : null
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    ...mapMutations('SearchModule', ['SET_CURRENT_TEXT_SEARCH']),
    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    async updateRecentSearches (search, removing = false) {
      if (!search.length) return
      const newSearch = {
        search,
        createdAt: +new Date()
      }
      const oldRecentSearchs = this.getUser?.recentSearches || []
      const recentSearches = removing
        ? oldRecentSearchs.filter(s => s.search !== search)
        : [newSearch, ...oldRecentSearchs.filter(s => s.search !== search)].slice(0, 10)
      try {
        const updatedUser = { ...this.getUser }
        updatedUser.recentSearches = recentSearches
        this.SET_USER(updatedUser)
        await FirebaseAPI.Users.update(this.getUser.user_id, { recentSearches })
      } catch (error) {
        console.error('Failed to update recent searches:', error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to update recent searches. See console for details.'
        })
      }
    },
    handleSearchInput (event) {
      const searchTerm = event.target.value
      this.$emit('update:text-search', searchTerm)
      this.fetchAutocomplete(searchTerm)
    },
    handleSearchSubmit (search = null) {
      if (!search && this.textSearch.length && this.textSearch.length < MIN_KEYWORD_LENGTH) return
      if (search) this.$emit('update:text-search', search)
      this.collapseDropdown()
      this.updateRecentSearches(search ?? this.textSearch)
      this.fetchAutocomplete(search ?? this.textSearch)
      this.$emit('submit')

      // Update Search State with the proper items
      this.SET_CURRENT_TEXT_SEARCH(this.textSearch)
      sendEventSocket('search')
    },
    handleClearSearch () {
      this.$refs['search-input'].focus()
      this.$emit('update:text-search', '')
      
      // Check if a previous search query was submitted, if so, we refetch the ads
      const queryParams = new URLSearchParams(window.location.search)
      const searchQuery = queryParams.get('search') || ''
      if (searchQuery.length) this.$emit('submit') // Refetch ads

      this.autocompleteResults = null
    },
    fetchAutocomplete: _.debounce(async function (searchTerm) {
      if (!searchTerm.length || this.keywordsOnly) {
        this.autocompleteResults = null
        return
      }

      // Delay loading state by 100ms so it doesn't flicker on cached requests
      const loadTimeout = setTimeout(() => { this.loadingAutocomplete = true }, 100)

      try {
        const response = await ForeplayAPI.Search.getAutocompleteResults(searchTerm)
        this.autocompleteResults = response.data
      } catch (error) {
        console.error('Failed to fetch autocomplete results:', error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to autocomplete. See console for details.'
        })
      } finally {
        clearTimeout(loadTimeout)
        this.loadingAutocomplete = false
      }
    }, 300),

    // ==============================================================================
    // ================================= UI METHODS =================================
    // ==============================================================================

    expandDropdown () {
      if (this.dropdownExpanded) return
      this.navIndex = null
      this.dropdownExpanded = true
      this.$nextTick(() => {
        this.$refs['search-input'].focus()
        window.addEventListener('keydown', this.handleKeyboardNavigation)
      })
    },
    collapseDropdown () {
      // Cleanup states in the dropdown
      this.$refs['recent-searches']?.removeEventListener('scroll', this.handleRecentSearchScroll)
      window.removeEventListener('keydown', this.handleKeyboardNavigation)
      this.navIndex = null

      // Collapse the dropdown
      this.dropdownExpanded = false
      this.$refs['search-input'].blur()
    },
    scrollRecentSearches (direction) {
      const recentSearches = this.$refs['recent-searches']
      if (!recentSearches) return

      // Disable pointer events until the user moves the mouse more than 4px
      // Prevents erroneous clicking when they reach the end of the scroll
      recentSearches.style.pointerEvents = 'none'
      let initialMouseX, initialMouseY
      const trackMouseMovement = (event) => {
        if (!initialMouseX || !initialMouseY) {
          initialMouseX = event.clientX
          initialMouseY = event.clientY
          return
        }
        const deltaX = Math.abs(event.clientX - initialMouseX)
        const deltaY = Math.abs(event.clientY - initialMouseY)
        if (deltaX >= 4 || deltaY >= 4) {
          recentSearches.style.pointerEvents = 'auto'
          window.removeEventListener('mousemove', trackMouseMovement)
        }
      }
      window.addEventListener('mousemove', trackMouseMovement)

      // Scroll the recent searches
      const distance = Math.round(recentSearches.clientWidth / 2.2)
      const scrollAmount = direction === 'left' ? -distance : distance
      recentSearches.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    },
    handleRecentSearchScroll (event = null) {
      const scroller = event?.target ?? this.$refs['recent-searches']
      if (!scroller) return
      this.rsOverflows = scroller.scrollWidth > scroller.clientWidth
      this.rsMinScroll = Math.floor(scroller.scrollLeft) === 0
      this.rsMaxScroll = Math.abs(scroller.clientWidth - (scroller.scrollWidth - Math.ceil(scroller.scrollLeft))) <= 2
    },
    handleAutocompleteScroll (event = null) {
      const scroller = event?.target ?? this.$refs['ac-options-list']
      if (!scroller) return
      this.acOverflows = scroller.scrollHeight > scroller.clientHeight
      this.acMinScroll = Math.floor(scroller.scrollTop) === 0
      this.acMaxScroll = Math.abs(scroller.clientHeight - (scroller.scrollHeight - Math.ceil(scroller.scrollTop))) <= 2
    },

    // ======================== KEYBOARD NAVIGATION ========================
    handleKeyboardNavigation (event) {
      const validKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab']
      if (!validKeys.includes(event.key)) return
      event.preventDefault()
      switch (event.key) {
        case "ArrowDown":
          this.navigateNext(); break
        case "ArrowUp":
          this.navigatePrev(); break
        case "Enter":
          this.selectNavItem(); break
        case "Escape":
          this.collapseDropdown(); break
      }
    },
    navigateNext () {
      if (this.navIndex === null || this.navIndex < this.navigationItems.length - 1) {
        this.navIndex = this.navIndex === null ? 0 : this.navIndex + 1
        this.scrollToItem(this.currentNavItem.ref)
      }
    },
    navigatePrev () {
      if (this.navIndex === 0) {
        this.navIndex = null
      } else if (this.navIndex !== null) {
        this.navIndex--
        this.scrollToItem(this.currentNavItem.ref)
      }
    },
    selectNavItem () {
      if (this.currentNavItem) this.currentNavItem.action()
      else this.handleSearchSubmit()
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        let item = this.$refs[ref][0]
        if (!item) return

        if (item.$el) item = item.$el
        const itemRect = item.getBoundingClientRect()
        const container = this.$refs['ac-options-list']
        const containerRect = container.getBoundingClientRect()

        let scrollPosition = container.scrollTop
        const offset = 20
        const overflowTop = containerRect.top - itemRect.top
        const overflowBottom = itemRect.bottom - containerRect.bottom
        if (this.navIndex === 0) {
          scrollPosition = 0
        } else if (this.navIndex === this.navigationItems.length - 1) {
          scrollPosition = container.scrollHeight
        } else if (overflowTop > 0) {
          scrollPosition = container.scrollTop - overflowTop - offset
        } else if (overflowBottom > 0) {
          scrollPosition = container.scrollTop + overflowBottom + offset
        }
        container.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      })
    }
  }
}
</script>

<style scoped>
.discovery-search {
  position: absolute;
  top: -0.5rem;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: background-color 100ms ease-in-out;
}
.discovery-search.expanded {
  background-color: #1A1A23;
}
.ac-top-fade-overlay {
  background: linear-gradient(180deg, #1A1A23 0%, transparent 100%);
  pointer-events: none;
}
.ac-bottom-fade-overlay {
  background: linear-gradient(0deg, #1A1A23 0%, transparent 100%);
  pointer-events: none;
}

/* RECENT SEARCHES STYLING */
@property --rs-grad-opacity-1 {
  syntax: '<number>';
  initial-value: 0.08;
  inherits: false;
}
@property --rs-grad-opacity-2 {
  syntax: '<number>';
  initial-value: 0.04;
  inherits: false;
}
@property --rs-grad-opacity-3 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
.recent-search {
  background: linear-gradient(180deg,  rgba(255, 255, 255, var(--rs-grad-opacity-1)) 0%, rgba(255, 255, 255, var(--rs-grad-opacity-2)) 100%), 
    rgba(255, 255, 255, var(--rs-grad-opacity-3));
  --rs-grad-opacity-1: 0.08;
  --rs-grad-opacity-2: 0.04;
  --rs-grad-opacity-3: 0.12;
  transition: --rs-grad-opacity-1 100ms ease-in-out, --rs-grad-opacity-2 100ms ease-in-out, --rs-grad-opacity-3 100ms ease-in-out;
}
.recent-search:hover {
  --rs-grad-opacity-1: 0.12;
  --rs-grad-opacity-2: 0.06;
  --rs-grad-opacity-3: 0.16;
}
.remove-button {
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
}
.remove-button:hover {
  color: white;
  background-color: rgba(6, 7, 15, 0.12);
}
.rs-left-fade-overlay {
  background: linear-gradient(90deg, #1A1A23 0%, #1A1A23 37%, transparent 100%);
  pointer-events: none;
}
.rs-right-fade-overlay {
  background: linear-gradient(270deg, #1A1A23 0%, #1A1A23 37%, transparent 100%);
  pointer-events: none;
}
.scroll-nav-btn {
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(20px);
  transition: background-color 100ms ease-in-out;
}
.scroll-nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.32);
}

/* SEARCH INPUT STYLING */
@property --gradient-opacity {
  syntax: '<number>';
  initial-value: 0;
  inherits: false;
}
.search-input-container {
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #EBEFF3;
  background: linear-gradient(90deg, rgba(76, 76, 82, var(--gradient-opacity)) 0%, rgba(52, 52, 59, var(--gradient-opacity)) 100%);
  transition: box-shadow 100ms ease-in-out, --gradient-opacity 100ms ease-in-out;
}
.search-input-container.expanded {
  --gradient-opacity: 1;
  box-shadow: none;
}
.search-input-container.collapsed {
  --gradient-opacity: 0;
  cursor: pointer;
}
.search-input-container.collapsed:hover {
  box-shadow: 0px 1px 2px 0px rgba(32, 36, 50, 0.08), 0px 0px 0px 1px #808899; /* neutral-400 */
}
.search-input {
  color: black;
  transition: color 100ms ease-in-out;

  /* Remove default browser styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  outline: none;

  /* typography */
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.search-input-container.expanded .search-input {
  color: white;
}
.search-input:focus {
  /* Remove default browser styles */
  outline: none;
  border: none;
  box-shadow: none;
}
.search-input::placeholder {
  transition: color 100ms ease-in-out, opacity 100ms ease-in-out;
}
.search-input-container.collapsed .search-input::placeholder {
  color: #5E6678; /* text-text-normal */
}
.search-input-container.collapsed:hover .search-input::placeholder {
  color: #303546; /* text-text-muted */
}
.search-input-container.expanded .search-input {
  caret-color: white;
}
.search-input-container.expanded .search-input::placeholder {
  color: white;
  opacity: 0.44;
}

/* ========= Vue <transition> classes ========= */
.slowfade-enter-active, .slowfade-leave-active {
  transition: opacity 150ms ease-in-out;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 100ms ease-in-out;
}
.quickfade-enter-active, .quickfade-leave-active {
  transition: opacity 50ms ease-in-out;
}
.dropdown-enter-active {
  transition: opacity 200ms ease-in-out;
}
.dropdown-leave-active {
  position: absolute;
  top: 44px;
  width: calc(100% - 16px);
}
.rs-kws-swap-enter-active {
  transition: opacity 75ms ease-in-out;
}
.rs-kws-swap-leave-active {
  transition: opacity 75ms ease-in-out;
  position: absolute;
  top: 44px;
  width: calc(100% - 16px);
}
.input-action-swap-enter-active {
  transition: opacity 100ms ease-in-out;
}
.input-action-swap-leave-active {
  transition: opacity 100ms ease-in-out;
  position: absolute;
  right: 4px;
}
.fade-enter-from, .fade-enter, .fade-leave-to, .quickfade-enter-from, .quickfade-enter, .quickfade-leave-to, 
.slowfade-enter-from, .slowfade-enter, .slowfade-leave-to, .dropdown-enter-from, .dropdown-enter, .dropdown-leave-to,
.rs-kws-swap-enter-from, .rs-kws-swap-enter, .rs-kws-swap-leave-to, .input-action-swap-enter-from, .input-action-swap-enter, .input-action-swap-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from, .quickfade-enter-to, .quickfade-leave-from,
.slowfade-enter-to, .slowfade-leave-from, .dropdown-enter-to, .dropdown-leave-from,
.rs-kws-swap-enter-to, .rs-kws-swap-leave-from, .input-action-swap-enter-to, .input-action-swap-leave-from {
  opacity: 1;
}
</style>